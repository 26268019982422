<script>
import * as echarts from "echarts";
import TResult from "@/components/Result.vue";

export default {
	name: "Mht",
	components: {TResult},
	props: {
		value: {
			type: Object | Array,
			default: function () {
				return {
					MHT: [],
				};
			},
		},
	},
	watch: {
		value() {
			this.$nextTick(() => {
				let chartDom = document.getElementById("mht");
				if (!chartDom) return;
				let myChart = echarts.init(chartDom);
        const total = Number(this.value.MHT.info.total)
				let option = {
					series: [
						{
							type: "gauge",
							startAngle: 180,
							endAngle: 0,
							center: ["50%", "88%"],
              radius: "100%",
							min: 0,
							max: 1,
							axisLine: {
								lineStyle: {
									width: 10,
									color: [
										[0.711, "#2FD931"],
										[1, "#FF4D4D"],
									],
								},
							},
							pointer: {
								icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
								length: "20%",
								width: 10,
								offsetCenter: [0, "-40%"],
								itemStyle: {
									color: "auto",
								},
							},
							axisTick: {
								length: 5,
								lineStyle: {
									color: "#E5F2FF",
                  width: 3,
									borderRadius: 5,
								},
							},
							splitLine: {show: false},
							axisLabel: {show: false},
							detail: {
								fontSize: 30,
								offsetCenter: [0, "-15%"],
								valueAnimation: true,
								formatter: function (value) {
									return Math.round(value * total) + "";
								},
								color: "inherit",
							},
							data: [
								{
									value: Number(this.value.MHT.info.average) / total,
								},
							],
						},
					],
				};
				option && myChart.setOption(option);
        window.addEventListener('resize', function () {
          myChart.resize();
        });
			});
		},
	},
	methods: {
		getType(value) {
			return Object.prototype.toString.call(value).slice(8, -1).toLowerCase();
		},
	},
};
</script>

<template>
	<div class="main">
		<div style="font-weight: normal;font-size: 24rem;color: #1E3AA2;">MHT测试结果</div>
		<div v-if="getType(value.MHT) === 'object'" id="mht"></div>
		<t-result v-else class="mbti-main" style="margin-top: -68rem" text="暂无数据" type="empty"></t-result>
		<div v-if="getType(value.MHT) === 'object'" style="font-weight: 400;font-size: 18rem;color: #3D3D3D;width: 100%;text-align: center">{{ value.MHT.info.evaluate }}</div>
		<div v-if="getType(value.MHT) === 'object'" style="font-weight: normal;font-size: 14rem;color: #888888;margin-top: 35rem">测试时间：{{ value.MHT.info.completion_at }}</div>
	</div>
</template>

<style lang="scss" scoped>
.main {
	width: 463rem;
	height: 410rem;
	background: #FFFFFF;
	border-radius: 8rem;
	padding: 24rem;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	#mht {
		flex: 1;
	}
}
</style>
