<script>
export default {
	name: "InfoRight",
	props: {
		oss: {
			type: String,
			default: "",
		},
		value: {},
	},
	data() {
		return {
			studentProfile: [
				{field: "监护人姓名", value: "name"},
				{field: "与学生的关系", value: "relationship_name"},
				{field: "监护人电话", value: "phone"},
				{field: "是否为独生子女", value: "only_child"},
				{field: "家庭类型", value: "home_type"},
				{field: "家庭住址", value: "home_address"},
			],
		};
	},
};
</script>

<template>
	<div class="info-main-right">
		<div style="align-items: center;display: flex">
			<img :src="oss+'home.png'" alt="" style="width: 30.03rem;height: 27.6rem">
      <span style="width: 96rem;height: 34rem;font-weight: 600;font-size: 24rem;color: #1E3AA2;margin-left: 16rem">家庭信息</span>
		</div>
    <div style="width: 100%;flex: 1;padding-top: 86rem;box-sizing: border-box">
			<div style="display: flex; flex-wrap: wrap; width: 100%;">
				<div v-for="(item, index) in studentProfile" :key="index" :style="{ boxSizing: 'border-box', width: index < 4 ? 'calc(50% - 12rem)' : '100%' }" style="margin-bottom: 24rem;display: flex">
					<div :style="{ minWidth: (index ===1 || index === 3) ? '122rem' : '94rem', height: '18rem', fontWeight: 'normal', fontSize: '14rem', color: '#888888', display: 'inline-block' }">
            <i v-if="index > 3" :class="index===4?'el-icon-price-tag':'el-icon-location-information'" style="color: #1C4EFD;font-size: 16rem"></i>
						{{ item.field }}
					</div>
					<div style="height: 18rem; font-size: 14rem; color: #3C3C3C;">{{ value[item.value] }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.info-main-right {
  width: 522rem;
  //height: 100%;
	background: #FFF;
	border-radius: 8rem;
	box-sizing: border-box;
	padding: 14rem 24rem 32rem;
	display: flex;
	flex-direction: column;
	margin-left: 24rem;
}

</style>