<template>
	<div v-show="visible" class="custom-dialog">
		<div style="width: 100%;display: flex;justify-content: center">
			<transition name="dialog-fade">
				<div v-if="visible" class="box" style="display: flex;flex-direction: column;align-items: center;justify-content: center">
					<div class="title">
						<span style="font-size: 18rem;color: #1E3AA2;font-weight: 600">全部信息</span>
						<i class="el-icon-close" @click="onClosed"></i>
					</div>

					<el-timeline style="padding: 24rem">
						<el-timeline-item v-for="(i, index) in activities" :key="index" :color="i.color">
							<div v-if="index< 4">{{ i.content }}</div>
							<div v-if="index< 4" style="width: 100%;min-height: 103rem;background: #F6F9FF;border-radius: 4rem;margin-top: 16rem;padding: 24rem;box-sizing: border-box">
								<div v-if="index===0" style="display: flex">
									<div style="width: 272rem">
										<div>
											<span class="label">姓名</span>
											<span>{{ allInfo.base.student_name }}</span>
										</div>
										<div style="margin: 16rem 0">
											<span class="label">性别</span>
											<span>{{ allInfo.base.student_gender }}</span>
										</div>
										<div>
											<span class="label">是否有身体疾病</span>
											<span>{{ allInfo.all.disease_history }}</span>
										</div>
                    <div v-if="allInfo.all.disease_details" style="width: 238rem;min-height: 66rem;background: #FFF5D9;border-radius: 4rem;margin-top: 8rem;padding: 8rem;box-sizing: border-box;display: flex">
											<div style="font-size: 12rem;color: #F6AD3C;">注：</div>
											<div style="font-size: 12rem;color: #888888;">{{ allInfo.all.disease_details }}</div>
										</div>
									</div>
									<div style="width: 272rem">
										<div>
											<span class="label">出生日期</span>
											<span>{{ allInfo.base.birthday }}</span>
										</div>
										<div style="margin: 16rem 0">
											<span class="label">民族</span>
											<span>{{ allInfo.base.nation_name }}</span>
										</div>
										<div>
											<span class="label">是否有心理障碍</span>
											<span>{{ allInfo.all.psychological_disorders }}</span>
										</div>
                    <div v-if="allInfo.all.psychological_disorders_details" style="width: 238rem;min-height: 66rem;background: #FFF5D9;border-radius: 4rem;margin-top: 8rem;padding: 8rem;box-sizing: border-box;display: flex">
											<div style="font-size: 12rem;color: #F6AD3C;">注：</div>
											<div style="font-size: 12rem;color: #888888;">{{ allInfo.all.psychological_disorders_details }}</div>
										</div>
									</div>
								</div>
								<div v-if="index===1">
									<div style="display: flex">
                    <div style="width: 272rem;flex-shrink: 0;">
											<div>
                        <span class="label">监护人姓名</span>
												<span>{{ allInfo.family.name }}</span>
											</div>
											<div style="margin: 16rem 0">
                        <span class="label">监护人电话</span>
												<span>{{ allInfo.family.phone }}</span>
											</div>
											<div>
                        <span class="label">家庭类型</span>
												<span>{{ allInfo.family.home_type }}</span>
											</div>
										</div>
										<div>
											<div>
												<span class="label">与学生的关系</span>
												<span>{{ allInfo.family.relationship_name }}</span>
											</div>
											<div style="margin: 16rem 0">
												<span class="label">是否为独生子女</span>
												<span>{{ allInfo.family.only_child }}</span>
											</div>
											<div>
												<span class="label" style="position: absolute;">家庭住址</span>
												<div style="padding-left: 122rem;">{{ allInfo.family.home_address }}</div>
											</div>
										</div>
									</div>
									<div style="display: flex">
                    <span class="label" style="margin-top: 16rem">家庭情况</span>
                    <div style="background: #FFFFFF;border-radius: 4rem;margin-top: 16rem;font-size: 12rem;color: #888888;padding: 8rem;box-sizing: border-box">
											{{ allInfo.all.home_details }}
										</div>
									</div>
								</div>
								<div v-if="index===2">
									<div style="display: flex">
										<div style="width: 274rem">
											<div>
                        <span class="label">初中毕业学校</span>
												<span>{{ allInfo.all.student_attend_graduation_school }}</span>
											</div>
											<div style="margin: 16rem 0">
                        <span class="label">初中是否留级</span>
												<span>{{ allInfo.all.student_attend_repetition }}</span>
											</div>
											<div>
                        <span class="label">入学总分</span>
												<span>{{ allInfo.all.senior_score }}</span>
											</div>
										</div>
										<div>
											<div>
												<span class="label">中考所在区域</span>
												<span>{{ allInfo.all.senior_region }}</span>
											</div>
											<div style="margin: 16rem 0">
												<span class="label">初中是否休学</span>
												<span>{{ allInfo.all.student_attend_suspend }}</span>
											</div>
										</div>
									</div>
									<div style="width: 599rem;height: 62rem;background: #FFFFFF;border-radius: 4rem;margin-top: 24rem;padding: 10rem 24rem;display: flex;justify-content: space-between">
                    <span v-if="!allInfo.all.senior_score_subject.length">-</span>
                    <template v-else>
                      <div v-for="i in allInfo.all.senior_score_subject" style="font-size: 12rem;color: #3C3C3C;display: flex;flex-direction: column;justify-content: space-between;padding: 0 4rem;align-items: center">
                        <div>{{ i.subject_name }}</div>
                        <div>{{ i.subject_entrance }}</div>
                      </div>
                    </template>
									</div>
								</div>
								<div v-if="index===3" style="display: flex">
                  <div class="label-item" style="display: grid;grid-template-columns: 1fr 1fr;grid-row-gap: 14rem;grid-column-gap: 12rem">
										<div>
											<span class="label">学号</span>
											<span>{{ allInfo.base.student_no }}</span>
										</div>
										<div>
											<span class="label">宿舍</span>
											<span>{{ allInfo.base.dormitory }}</span>
										</div>
										<div>
											<span class="label">校区</span>
											<span>{{ allInfo.base.school_name }}</span>
										</div>
										<div>
											<span class="label">年级</span>
											<span>{{ allInfo.base.grade_name }}</span>
										</div>
										<div style="overflow-wrap: break-word">
											<span class="label">行政班</span>
											<span>{{ allInfo.base.class_name }}</span>
										</div>
										<div>
											<span class="label">班主任</span>
											<span>{{ allInfo.base.teacher_name }}</span>
										</div>
										<div>
											<span class="label">学习方向</span>
											<span>{{ allInfo.base.learning_direction }}</span>
										</div>
										<div>
											<span class="label">专业方向</span>
											<span>{{ allInfo.base.major }}</span>
										</div>
										<div>
											<span class="label">政治面貌</span>
											<span>{{ allInfo.base.political_outlook }}</span>
										</div>
										<div>
											<span class="label">学籍所在地</span>
											<span>{{ allInfo.base.student_address }}</span>
										</div>
									</div>
								</div>
							</div>
						</el-timeline-item>
					</el-timeline>
				</div>
			</transition>
		</div>

	</div>
</template>

<script>
export default {
	name: "l-dialog",
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		allInfo: {},
	},
	data() {
		return {
			activities: [{
				content: "个人信息",
				color: "#AAC4FF",
			}, {
				content: "家庭信息",
				color: "#AAC4FF",
			}, {
				content: "初中学习概况",
				color: "#AAC4FF",
			}, {
				content: "在校信息",
				color: "#AAC4FF",
			}, {
				content: "在校信息",
				color: "#AAC4FF",
			}],
		};
	},
	methods: {
		onClosed() {
			this.$emit("update:visible", false);
			this.$emit("closed");
		},
	},

};
</script>

<style lang="scss" scoped>
.dialog-fade-enter-active,
.dialog-fade-leave-active {
	transition: all 0.3s ease;
}

.dialog-fade-enter-to,
.dialog-fade-leave-to {
	opacity: 0;
	transform: translateY(-20rem);
}

.custom-dialog {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 105rem 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	z-index: 9999999;
	overflow-y: auto;
}

.box {
	width: 720rem;
	min-height: 1160rem;
	background: #FFFFFF;
	border-radius: 8rem;

	.title {
		width: 720rem;
		height: 50rem;
		border-bottom: 1rem solid #F6F9FF;
		padding: 0 24rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

::v-deep .el-timeline-item__tail {
	border-left: 2rem dashed #D8D8D8;
}

.el-timeline-item:last-of-type {
	display: none;
}

.label {
	display: inline-block;
	min-width: 122rem;
	font-size: 14rem;
	color: #888888;
}

.label1 {
	display: inline-block;
	width: 94rem;
	font-size: 14rem;
	color: #888888;
}

.label2 {
	display: inline-block;
	width: 108rem;
	font-size: 14rem;
	color: #888888;
}

.value {
	font-size: 14rem;
	color: #3C3C3C;
}

.label-item > div {
	display: flex;
}
</style>
