<script>
export default {
	name: "Counseling",
	props: {
		oss: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			imgBg: ["1.png", "2.png", "3.png", "4.png"],
			infoData: [
				{
					category: "本学期学科一对一辅导",
					numText: "总辅导数",
					bg: "1.png",
					value: "subject",
					change: "change",
				},
				{
					category: "本学期德育一对一辅导",
					numText: "总辅导数",
					bg: "2.png",
					value: "moral_education",
					change: "change",
				},
				{
					category: "本学期教务常规检查",
					numText: "总检查数",
					bg: "3.png",
					value: "educational",
					change: "change",
				},
				{
					category: "本学期心理咨询记录",
					numText: "总咨询数",
					bg: "4.png",
					value: "psychology",
					change: "change",
				},
			],
			semesterStatistics: {},
			test: 1,
		};
	},
	mounted() {
		this.$_axios2.get("api/student/details/semester-statistics?student_id=" + this.$route.query.id).then((res) => {
			this.semesterStatistics = res.data.data;
		});
	},
};
</script>

<template>
	<div style="width: 100%;display: flex;justify-content: space-between">
		<div v-for="(i,n) in infoData" :style="{ backgroundImage: 'url(' + oss + i.bg +')', backgroundSize: '100% 100%' }" class="item">
			<span class="item-text">{{ i.category }}</span>
			<div style="margin-top: 30rem;display: flex">
				<div class="item-num">
					{{ semesterStatistics[i.value] ? semesterStatistics[i.value].current_num : "" }}
				</div>
				<div v-if="semesterStatistics[i.value]" style="margin-left: 32rem">
					<div v-if="semesterStatistics[i.value].change  !== '-'" style="display: flex;align-items: center">
						<span style="font-size: 14rem;color: #888888;margin-right: 8rem">较上学期</span>
						<span :style="{color: semesterStatistics[i.value].change  === 'drop' ? '#F6AD3C' : '#23C343'}" style="font-size: 14rem;">{{ semesterStatistics[i.value] ? semesterStatistics[i.value].change_value : "" }}</span>
						<img v-if="semesterStatistics[i.value]" :src="oss+semesterStatistics[i.value].change+'.png'" alt="" style="width: 16rem;height: 16rem;margin-left: 4rem">
					</div>
					<div v-else style="display: flex;align-items: center">
						<span style="font-size: 14rem;">-</span>
					</div>
          <div style="margin-top: 4rem">
						<span style="font-size: 14rem;color: #888888;;margin-right: 8rem">{{ i.numText }}</span>
						<span style="font-weight: 400;font-size: 14rem;color: #1C4EFD;">{{ semesterStatistics[i.value] ? semesterStatistics[i.value]?.total_num : "" }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.item {
  width: 360rem;
	height: 150rem;
	padding: 24rem 0 30rem 33rem;
	box-sizing: border-box;

	&-text {
		width: 160rem;
		height: 18rem;
		font-weight: 400;
		font-size: 16rem;
		color: #1E3AA2;
	}

	&-num {
		width: 92rem;
		height: 47rem;
		font-weight: 700;
		font-size: 36rem;
		color: #1C4EFD;
	}
}
</style>
