<script>
import * as echarts from "echarts";
import TResult from "@/components/Result.vue";
import Empty from "@/views/moral/teacher-work/components/Empty.vue";
import EchartsIcon from "@/views/edu/edu-info/student-manager/components/Echarts/EchartsIcon.vue";

export default {
	name: "StudyHabits",
	components: {TResult, Empty, EchartsIcon},
	data() {
		return {};
	},
	props: {
		value: {
			type: Array,
			default: function () {
				return [];
			},
		},
	},
	watch: {
		value(newValue) {
			if (newValue.length) this.init();
		},
	},
	methods: {
		init() {
			this.$nextTick(() => {
				let chartDom = document.getElementById("main");
				let myChart = echarts.init(chartDom);
				const chart = [
					"没完成",
					"不合格",
					"仅完成",
					"比较好",
					"非常好",
				];
				let option = {
					grid: {
						left: "3%",
						right: "5%",
            top: "3%",
            bottom: "3%",
						containLabel: true,
					},
					tooltip: {
						trigger: "axis",
						formatter: function (params) {
							return `<div style="display: flex;align-items: center">
										<div style="width: 6rem;height: 6rem;background: #BBCBFF;border-radius: 50%;margin-right: 10rem;margin-top: -6rem"></div>
										<div style="font-size: 16rem">${params[0].data}分 ${chart[params[0].data - 1] ? chart[params[0].data - 1] : ''}</div>
							</div>` + `<span style='font-size: 16rem;color: #888888'><span style='margin-right: 10rem;margin-left: 14rem'>日期</span>${params[0].name}</span>`;
						},
					},
					xAxis: [
						{
							type: "category",
							boundaryGap: false,
							data: this.value.map(i => i.coach_day),
						},
					],
          yAxis: [{type: "value", max: 5,}],
					series: [
						{
							type: "line",
							data: this.value.map(i => i.score),
							areaStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: "#BCCCFF",
								}, {
									offset: 1,
									color: "#fff",
								}]),
							},
							lineStyle: {
								color: "#BBCBFF",
							},
							itemStyle: {
								color: "#BBCBFF",
							},
						},
					],
				};
				option && myChart.setOption(option);
        window.addEventListener('resize', function () {
          myChart.resize();
        });
			});
		},
	},
};
</script>

<template>
	<div>
		<EchartsIcon text="德育六大习惯检查"></EchartsIcon>
    <div v-if="value.length" id="main" style="width: 992rem;height: 440rem;background: #FFFFFF;border-radius: 8rem;padding: 24rem 0"></div>
		<t-result v-else id="main" style="width: 992rem;height: 440rem;background: #FFFFFF;border-radius: 8rem" text="暂无数据" type="empty"></t-result>
	</div>
</template>

<style lang="scss" scoped>

</style>
